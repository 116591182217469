import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

function RelatedArticle({ className, articles }) {
  if (!articles.length) return null
  return (
    <div className={className}>
      <h3 className="text-3xl font-semibold mb-2 knowledgeBaseTextColor">
        Related Articles:
      </h3>
      <ul className="pl-10 list-disc">
        {articles.map((article, index) => (
          <li key={index}>
            <Link to={article.uri.replace('/blog', '')}>{article.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

RelatedArticle.propTypes = {
  className: PropTypes.string,
  articles: PropTypes.array.isRequired,
}

export default RelatedArticle
