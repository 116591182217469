import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

function PostHeader({
  className,
  title,
  uri,
  date,
  modified,
  author,
  categories,
  tags,
  post,
}) {
  const dateFormat = dayjs(date).format('MMMM D, YYYY')
  const modifiedFormat = dayjs(modified).format('MMMM D, YYYY')

  const heading = post ? (
    <>
      <h1
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <span className="knowledgeBaseBorder mb-4" />
    </>
  ) : (
    <h2 className="inline">
      <Link
        to={uri.replace('/blog', '')}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        className="font-semibold text-4xl"
      />
      <span className="knowledgeBaseBorder mb-4" />
    </h2>
  )

  return (
    <div className={className}>
      {heading}
      <div>
        Posted on <time dateTime={date}>{dateFormat}</time> by{' '}
        {author?.node && (
          <Link to={author.node.uri.replace('/blog', '/kb')}>
            {author.node.name}
          </Link>
        )}
        {modified && modifiedFormat !== dateFormat && (
          <>
            {' | Updated: '}
            <time dateTime={modified}>{modifiedFormat}</time>
          </>
        )}
        <div>
          Category:{' '}
          {categories && categories.nodes[0] && (
            <Link
              to={categories.nodes[0].uri.replace(
                '/blog/category',
                '/kb/category'
              )}
            >
              {categories.nodes[0].name}
            </Link>
          )}
          {tags && tags.nodes[0] && (
            <>
              {' | '}Tags:{' '}
              {tags.nodes.map((tag, i, array) => (
                <Link
                  key={tag.name}
                  to={tag.uri.replace('/blog/tag', '/kb/tag')}
                >
                  {tag.name}
                  {i < array.length - 1 ? ', ' : null}
                </Link>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

PostHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  modified: PropTypes.string,
  author: PropTypes.object,
  categories: PropTypes.object,
  tags: PropTypes.object,
  post: PropTypes.bool,
}

export default PostHeader
