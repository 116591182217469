import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import RelatedArticle from '../../components/kb/related-article'
import PostHeader from '../../components/kb/post-header'

function CardKbPost({
  title,
  uri,
  date,
  modified,
  readingTime,
  author,
  lwContent,
  categories,
  tags,
  relatedArticle,
}) {
  const post = {
    title,
    uri,
    date,
    modified,
    readingTime,
    author,
    categories,
    tags,
  }
  return (
    <div
      className="relative pb-4 mb-8 border-b"
      style={{ borderBottomColor: '#d9d9d9' }}
    >
      <PostHeader {...post} />
      <div className="my-2">
        <div className="text-grey-darker mb-4 text-sm md:text-base">
          Reading Time: {readingTime}{' '}
          {readingTime === '< 1' ? 'minute' : 'minutes'}
        </div>
        <div
          className="text-grey-darker mb-2 text-sm md:text-base"
          dangerouslySetInnerHTML={{
            __html: lwContent.content.substr(0, lwContent.content.indexOf('</p>')).concat('</p>'), // just like wordpress: display post card content for KB until end of first </p> tag rather than excerpt
          }}
        />
        <Link aria-label="Continue reading" to={uri.replace('/blog', '')}>
          Continue reading →
        </Link>
      </div>
      {relatedArticle && <RelatedArticle articles={relatedArticle.nodes} />}
    </div>
  )
}

CardKbPost.propTypes = {
  title: PropTypes.string,
  uri: PropTypes.string,
  date: PropTypes.string,
  modified: PropTypes.string,
  readingTime: PropTypes.string,
  author: PropTypes.object,
  content: PropTypes.string,
  categories: PropTypes.object,
  tags: PropTypes.object,
  relatedArticle: PropTypes.object,
}

export default CardKbPost
