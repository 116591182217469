import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import IconTelephone from '../../images/telephone.inline.svg'

function QuestionsWidget({ className }) {
  return (
    <div className={className}>
      <div className="text-lg font-semibold text-gray-800 mb-4">
        Have Some Questions?
        <div className="knowledgeBaseBorder" />
      </div>
      <p className="my-5">
        Our Sales and Support teams are available 24 hours by{' '}
        <Link to="/contact-us/">phone or e-mail</Link> to assist.
      </p>
      <div>
        <IconTelephone className="fill-current w-12 inline-block" />
        <div className="inline-block align-middle ml-4 font-semibold">
          <div>1.800.580.4985</div>
          <div>1.517.322.0434</div>
        </div>
      </div>
    </div>
  )
}

QuestionsWidget.propTypes = {
  className: PropTypes.string,
}

export default QuestionsWidget
