import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

function KBPagination({
  className,
  page,
  hasNextPage,
  hasPreviousPage,
  currentPage,
}) {
  return (
    <>
      {hasNextPage ? (
        <Link
          to={`${page}page/${currentPage + 1}/`}
          aria-label="Older posts"
          className={`mb-10 ${className ? className : ''}`}
        >
          {'← Older posts'}
        </Link>
      ) : null}
      {hasPreviousPage ? (
        <Link
          to={`${currentPage === 2 ? page : `${page}page/${currentPage - 1}/`}`}
          aria-label="Newer posts"
          className={`float-right ${className ? className : ''}`}
        >
          {'Newer posts →'}
        </Link>
      ) : null}
    </>
  )
}

KBPagination.propTypes = {
  page: PropTypes.string.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default KBPagination
